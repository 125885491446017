import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DisclosureItem from "../../components/shared/elements/DisclosureItem";
import ListGroup from "../../components/shared/data/ListGroup";
import { ProfessionalDevelopment } from "../../components/account-settings/ProfessionalDevelopment";
import PageHeader from "../../components/shared/elements/PageHeader";
import {
  checkInNewYear,
  saveAdiClickedPage,
} from "../../../core/services/adi-service";
import { useSharedData } from "../providers/SharedDataProvider";
import { AdiNetworkPagesEnum } from "../../../core/models/enums/adi-network-pages-enum";
import { NetworkPerksPages } from "../../../core/models/enums/network-perks-pages";
import { createClient } from "../../../../prismic";
import { filter } from "@prismicio/client";

const MoreWrapper: FC = () => {
  const adiClickedPage = async (e: any) => {
    await saveAdiClickedPage(e).catch(() => {});
  };

  const [networkPerksMenu, setNetworkPerksMenu] = useState([
    {
      name: "Earn commission with GoShorty",
      linkURL: "/ADI/pass-perks/goshorty",
    },
  ]);
  const { unReadCount, getUnread } = useSharedData();
  const [pages, setPages] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  // Function to fetch pages for Professional Development and Network Perks
  const fetchPages = async () => {
    setIsLoading(true);
    try {
      const response = await createClient.get({
        filters: [filter.at("document.type", "page")],
      });

      const mappedPages = response?.results
        ?.filter(
          (a) =>
            a.uid &&
            !(Object.values(NetworkPerksPages) as string[]).includes(a.uid)
        )
        .map((result) => ({
          name: result.data.title,
          linkURL: "/ADI/professional-development/" + result.uid,
        }));

      const mappedNetworkPages = response?.results
        ?.filter(
          (a) =>
            a.uid &&
            (Object.values(NetworkPerksPages) as string[]).includes(a.uid)
        )
        .map((result) => ({
          name: result.data.title,
          linkURL: "/ADI/professional-development/" + result.uid,
        }));

      setPages(mappedPages);
      // Merge networkPerksPages with networkPerksMenu
      setNetworkPerksMenu((prevMenu) => {
        const existingLinks = prevMenu.map((item) => item.linkURL);
        const uniqueNetworkPages = mappedNetworkPages.filter(
          (page) => !existingLinks.includes(page.linkURL)
        );
        return [...prevMenu, ...uniqueNetworkPages];
      });
    } catch (error) {
      console.error("Error fetching pages:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to check for new year and update network perks menu
  const updateNetworkPerksMenu = async () => {
    const res = await checkInNewYear();
    if ((res?.results ?? true) === false) {
      setNetworkPerksMenu((prevMenu) => [
        ...prevMenu,
        {
          name: "Discount & Rewards",
          linkURL: "/ADI/pass-perks/discounts-and-rewards",
        },
      ]);
    }
  };

  // Combined useEffect to handle both fetching pages and updating menu
  useEffect(() => {
    const fetchData = async () => {
      await fetchPages();
      await updateNetworkPerksMenu();
      getUnread();
    };
    fetchData();
  }, [unReadCount]);

  useEffect(() => {
    document.title = `ADI Network App | More | ADI Network`;
  }, []);
  
  return (
    <>
      <div className="px-5">
        <div className="mb-5">
          <PageHeader title="More" />
        </div>
        <ProfessionalDevelopment
          pages={pages}
          isLoading={isLoading}
          expanded={location?.hash?.toLowerCase() === "#professional"}
        />
        <DisclosureItem
          disclosureCard
          title="Network Perks"
          subTitle="Lorem ipsum dolor sit amet"
          disclosureIcon="pmf-icon-gift"
          expanded={location?.hash?.toLowerCase() === "#perks"}
        >
          <div className="px-4">
            <ListGroup data={networkPerksMenu} />
          </div>
        </DisclosureItem>

        <DisclosureItem
          disclosureCard
          title="Help & Feedback"
          subTitle="Lorem ipsum dolor sit amet"
          disclosureIcon="pmf-icon-help"
          expanded={location?.hash?.toLowerCase() === "#help"}
        >
          <div className=" px-4">
            <ListGroup
              data={[
                {
                  name: "Customer Service",
                  linkURL: "/ADI/help",
                },
                {
                  name: "Terms and Conditions",
                  linkURL: process.env.REACT_APP_ADINETWORK_URL + "/terms",
                  isRedirectLink: true,
                  onClick: () => {
                    adiClickedPage({
                      page_url: process.env.REACT_APP_ADINETWORK_URL + "/terms",
                      page_name: AdiNetworkPagesEnum.TermsAndConditions,
                    });
                  },
                },
                {
                  name: "FAQs",
                  linkURL: process.env.REACT_APP_ADINETWORK_URL + "/faq",
                  isRedirectLink: true,
                  onClick: () => {
                    adiClickedPage({
                      page_url: process.env.REACT_APP_ADINETWORK_URL + "/faq",
                      page_name: AdiNetworkPagesEnum.FAQs,
                    });
                  },
                },
              ]}
            />
          </div>
        </DisclosureItem>
      </div>
    </>
  );
};

export { MoreWrapper };
