/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useEffect, useState } from "react";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { PrivateRoutes } from "./PrivateRoutes";
import { Logout, AuthPage } from "../content/pages/auth";
import { RootState } from "../../setup";
import Page404 from "../content/pages/404";
import { StaticWelcomePage } from "../content/components/questionnaire/StaticWelcomePage";
import { CompleteADIQuestionnaireWrapper } from "../content/pages/questionnaire/CompleteADIQuestionnaireWrapper";

const Routes: FC = () => {
  const isAuthorized = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );
  const isLogin = useSelector<RootState>(({ auth }) => auth.isLogin);
  const hasUnVerifiedData = useSelector<RootState>(
    ({ auth }) => auth.hasUnVerifiedData
  );

  let location = useLocation();
  const [returnUrl, setReturnUrl] = useState<string>("");
  useEffect(() => {
    if (location.search && location.search.length > 1) {
      setReturnUrl(
        location.search.substring(location.search.indexOf("=") + 1) ?? returnUrl
      );
    }
  }, [location.search]);
  return (
    <>
      <Switch>
        {!isAuthorized ||
        location.pathname === "/auth/transition" ||
        location.pathname === "/auth/stripe-payment" ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPage />
          </Route>
        ) : (
          <>
            {Boolean(isAuthorized) && isLogin == true && !hasUnVerifiedData ? (
              <Switch>
                <Route path="/ADI" component={PrivateRoutes} />
                <Redirect from="/auth" to={returnUrl} />
                {location.search.includes("category") ||
                location.search.includes("videos") ? (
                  <Redirect from="/" to={`/ADI/cpd/${location.search}`} />
                ) : (
                  <Redirect
                    from="/"
                    to={
                      returnUrl && returnUrl != "" && returnUrl != "/"
                        ? returnUrl
                        : "/ADI/dashboard"
                    }
                  />
                )}
              </Switch>
            ) : (
              <>
                {Boolean(isAuthorized) &&
                (!isLogin || hasUnVerifiedData == true) ? (
                  <Switch>
                    <Route path="/welcome-page" component={StaticWelcomePage} />
                    <Route
                      path="/additional-details-adis"
                      component={CompleteADIQuestionnaireWrapper}
                    />
                    <Redirect
                      from="/auth"
                      to={`/additional-details-adis${location.search}`}
                    />
                    <Redirect
                      from="/"
                      to={`/additional-details-adis${location.search}`}
                    />
                  </Switch>
                ) : (
                  <PrivateRoutes />
                )}
              </>
            )}
          </>
        )}

        <Route path="/error" component={Page404} />
        <Route path="/logout" component={Logout} />
      </Switch>
    </>
  );
};

export { Routes };
