import { FC } from "react";
import ListGroup from "../shared/data/ListGroup";
import DisclosureItem from "../shared/elements/DisclosureItem";

interface ProfessionalDevelopmentProps {
  pages: Array<{ name: string; linkURL: string }>;
  isLoading: boolean;
  expanded: boolean;
}

const ProfessionalDevelopment: FC<ProfessionalDevelopmentProps> = ({
  pages,
  isLoading,
  expanded,
}) => {
  return (
    <>
      <DisclosureItem
        disclosureCard
        loading={isLoading}
        title="Professional Development"
        subTitle="Lorem ipsum dolor sit amet"
        disclosureImg="/assets/media/svg/help.svg"
        expanded={expanded}
      >
        <div className="px-4">
          <ListGroup
            className="border-b border-[#E6E6E6]"
            data={[
              {
                name: "All Videos and Articles",
                linkURL: "/ADI/cpd",
              },
            ]}
          />
          <ListGroup data={pages} />
          <ListGroup
            className="border-t border-[#E6E6E6]"
            data={[
              {
                name: "Standards Check Indicators",
                linkURL: "/ADI/standards-check-indicators",
              },
            ]}
          />
        </div>
      </DisclosureItem>
    </>
  );
};

export { ProfessionalDevelopment };
