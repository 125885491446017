import { FC, useEffect, useState } from "react";
import {
  getTestCenters,
  updateJobPrefrences,
  getWeekDays,
  getJobPrefrences,
} from "../../../../core/services/adi-service";
import * as Yup from "yup";
import { useFormik } from "formik";
import ReactGA from "react-ga4";
import { TestCenterDataModel } from "../../../../core/models/test-center-data-model";
import { JobPreferenceDataModel } from "../../../../core/models/job-preference-data-model";
import { DayModel } from "../../../../core/models/day-model";
import { IQuationnaireTabProps } from "../../../../core/models/quationnaire-tab-props";
import {
  scrollToFirstError,
  scrollToTagBySelector,
  scrollToTop,
} from "../../../helpers/ScrollHelper";
import { TestCentres } from "./TestCentres";
import Toggler from "../../shared/forms/Toggler";
import Alert from "../../shared/overlays/Alert";
import Button from "../../shared/elements/Button";
import { useHistory } from "react-router-dom";
import { Availability } from "./Availability";
import PageHeader from "../../shared/elements/PageHeader";
import Skeleton from "@mui/material/Skeleton";
import toast from "react-hot-toast";
import Notification from "../../shared/overlays/Notification";
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import DisclosureItem from "../../shared/elements/DisclosureItem";

const JobPreferences: FC<IQuationnaireTabProps> = (props) => {
  const history = useHistory();
  // const [testCenterLoading, setTestCenterLoading] = useState(false)
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const initialTestCentres = [new TestCenterDataModel()];
  const [testCenters, setTestCenters] = useState<Array<TestCenterDataModel>>(
    []
  );
  const [weekDays, setWeekDays] = useState<Array<DayModel>>([]);
  const [overLap, setOverLap] = useState<Array<boolean>>([]);
  const [jobPreference, setJobPreference] = useState<JobPreferenceDataModel>({
    adi_test_centers: [],
    is_same_gender_job: false,
    can_change_gender_preference: false,
    gender_name: "",
    availability: [],
    workingHolidays: [],
  });

  const validationSchema = Yup.object().shape({
    availability: Yup.array().min(1, "Availability is required"),
    test_centres: Yup.array().min(1, "Test centre is required"),
  });

  const setSubmit = () => {
    if (submitted == false) {
      setSubmitted(true);
    }

    scrollToErrorFunction();
  };

  useEffect(() => {
    if (jobPreference.adi_test_centers.length === 0) {
      setJobPreference({
        ...jobPreference,
        adi_test_centers: initialTestCentres,
      });
    }
  }, [jobPreference.adi_test_centers, testCenters]);

  const formik = useFormik({
    initialValues: {
      test_centres: jobPreference.adi_test_centers,
      is_same_gender_job: jobPreference.is_same_gender_job,
      workingHolidays: jobPreference.workingHolidays,
      availability: jobPreference.availability,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  const checkHasError = (): boolean => {
    if (formik.values.test_centres.length == 0) {
      return true;
    }
    if (
      formik.values.test_centres.findIndex(
        (a) => a.test_center_id == null || a.test_center_id.length == 0
      ) > -1
    ) {
      return true;
    }
    return false;
  };

  const checkAvailabilityError = (): boolean => {
    if (formik.values.availability.length == 0) {
      return true;
    }
    if (
      formik.values.availability.findIndex(
        (a) => a.from_time == "" || a.to_time == "" || a.day_id == null
      ) > -1
    ) {
      return true;
    }

    let x = formik.values.availability.filter((slot) => {
      if (slot.to_time && slot.from_time && slot.to_time <= slot.from_time) {
        return slot;
      }
    });
    if (x.length > 0) {
      return true;
    }

    return false;
  };

  const overlapping = (a: any, b: any) => {
    const getMinutes = (s: any) => {
      const p = s.split(":").map(Number);
      return p[0] * 60 + p[1];
    };
    return (
      getMinutes(a.to_time) > getMinutes(b.from_time) &&
      getMinutes(b.to_time) > getMinutes(a.from_time)
    );
  };
  const isOverlapping = (arr: any) => {
    let i, j;
    for (i = 0; i < arr.length - 1; i++) {
      for (j = i + 1; j < arr.length; j++) {
        if (overlapping(arr[i], arr[j])) {
          return true;
        }
      }
    }
    return false;
  };

  const checkOverLap = () => {
    setOverLap([]);
    let overLapExists: Array<boolean> = [];
    weekDays.forEach((day) => {
      let daySlots = formik.values.availability.filter((slot) => {
        if (slot.day_id == day.id) {
          return slot;
        }
      });
      overLapExists.push(isOverlapping(daySlots));
    });
    setOverLap(overLapExists);

    if (overLapExists.indexOf(true) > -1) {
      return true;
    }
  };

  function prepareSubmitData(values: any): JobPreferenceDataModel {
    const submitedData: JobPreferenceDataModel = {
      adi_test_centers: testCenters
        .filter(
          (a) =>
            formik.values.test_centres.findIndex(
              (b) => b.test_center_id == a.id
            ) > -1
        )
        .map((testCenter) => {
          const correspondingAdiTestCenter =
            jobPreference.adi_test_centers.find(
              (a) => a.test_center_id == testCenter.id
            );
          const test: TestCenterDataModel = {
            id: correspondingAdiTestCenter ? correspondingAdiTestCenter.id : "",
            test_center_id: testCenter.id != null ? testCenter.id : "",
            postal_code: testCenter.postal_code,
            test_center_name: testCenter.test_center_name,
            lat: testCenter.lat,
            long: testCenter.long,
          };
          return test;
        }),
      is_same_gender_job: values.is_same_gender_job,
      can_change_gender_preference: null,
      gender_name: null,
      availability: values.availability,
      workingHolidays: values.workingHolidays,
    };
    return submitedData;
  }
  function onSubmit(values: any, { setStatus, setSubmitting }: any) {
    setStatus("");
    let hasErrors = checkHasError();
    let hasAvailabilityError = checkAvailabilityError();
    let hasOverLap = checkOverLap();
    if (hasErrors || hasAvailabilityError || hasOverLap) {
      setSubmitting(false);
      scrollToErrorFunction();
      return;
    }
    if (props.parentWrapper == "questionnaire") {
      setSubmitting(false);
      setSubmitted(false);
    }
    submitJobPreferenceRequest();
  }

  function submitJobPreferenceRequest() {
    formik.setSubmitting(true);
    setSubmitted(true);
    let submitData: JobPreferenceDataModel = prepareSubmitData(formik.values);
    let loadingId = toast.custom(
      () => (
        <Notification
          colour="gray"
          title={"Updating job preferences..."}
          description={""}
          Icon={ExclamationCircleIcon}
        />
      ),
      {
        duration: 5000,
        position: "top-center",
      }
    );
    updateJobPrefrences(submitData)
      .then((data) => {
        toast.custom(
          () => (
            <Notification
              colour="green"
              title={"Saved successfully"}
              description={""}
              Icon={CheckCircleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
            id: loadingId,
          }
        );
        ReactGA.event("update_job_preferences");
        formik.setSubmitting(false);
        scrollToTop();
        if (props.parentWrapper === "questionnaire") {
          props.goToNextTab();
        } else {
          history.push("/ADI/dashboard");
        }
      })
      .catch((response) => {
        toast.custom(
          () => (
            <Notification
              colour="red"
              title={"Failed to update job preferences!"}
              description={""}
              Icon={ExclamationTriangleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
            id: loadingId,
          }
        );
        formik.setSubmitting(false);
        scrollToTop();
        if (response?.response?.data?.errors?.length) {
          formik.setStatus(response.response.data.errors[0].errorMessage);
        } else {
          formik.setStatus("Sorry, an error has occured");
        }
      });
  }

  const testCentersBinded = testCenters
    .filter(function (a) {
      return (
        jobPreference.adi_test_centers.findIndex(
          (b) => b.test_center_id == a.id
        ) == -1
      );
    })
    .map((testCenter) => ({
      label: testCenter.test_center_name,
      value: testCenter.id,
    }));

  const addTestCentre = () => {
    if (testCenters.length <= formik.values.test_centres.length) {
      return;
    }
    let tempTestCentres = formik.values.test_centres;
    const newTestCentre: TestCenterDataModel = new TestCenterDataModel();
    tempTestCentres.push(newTestCentre);
    formik.setFieldValue("test_centres", tempTestCentres);
  };

  const onTestCentresChanged = (e: any, key: number) => {
    if (formik.isSubmitting) {
      return;
    }
    const updatedTestCentres = formik.values.test_centres;
    updatedTestCentres[key].test_center_id = e.value;
    updatedTestCentres[key].test_center_name = e.label;
    formik.setFieldValue("test_centres", updatedTestCentres);
  };

  const onFlagJobGenderChanged = () => {
    if (formik.isSubmitting || !jobPreference.can_change_gender_preference) {
      return;
    }
    setJobPreference({
      ...jobPreference,
      is_same_gender_job: !!!jobPreference.is_same_gender_job,
    });
  };
  useEffect(() => {
    scrollToFirstError(formik);
  }, [formik.errors, formik.isSubmitting, formik.isValidating]);

  const scrollToErrorFunction = () => {
    if (submitted && checkAvailabilityError()) {
      scrollToTagBySelector("#availability-collapse");
    } else if (submitted && checkHasError()) {
      scrollToTagBySelector("#test-centers-collapse");
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const testCenterPromise = getTestCenters().then((res) => {
        setTestCenters(res.data.results);
      });
      const weekDaysPromise = getWeekDays().then((res) => {
        setWeekDays(res.data.results);
        // setWeekendLoading(false)
      });
      Promise.all([testCenterPromise, weekDaysPromise]).then(() => {
        getJobPrefrences()
          .then((res) => {
            let mappedAvailability = res.data.results.availability.map(
              (item) => {
                return {
                  ...item,
                  from_time: item.from_time.slice(0, 5),
                  to_time: item.to_time.slice(0, 5),
                };
              }
            );

            setJobPreference({
              adi_test_centers: res.data.results.adi_test_centers,
              is_same_gender_job: res.data.results.is_same_gender_job,
              can_change_gender_preference:
                res.data.results.can_change_gender_preference,
              gender_name: res.data.results.gender_name,
              availability: mappedAvailability,
              workingHolidays: res.data.results.workingHolidays,
            });
            formik.setFieldValue("availability", jobPreference.availability);
            setLoading(false);
          })
          .finally(() => {
            // setLoading(false)
          });
      });
    };
    fetchData();
  }, []);
  // }, [props.refresh])

  const deleteTestCentre = (index: number) => {
    const temp = formik.values.test_centres;
    temp.splice(index, 1);
    formik.setFieldValue("test_centres", temp);
  };

  const cancel = () => {
    if (props.parentWrapper === "questionnaire") {
      props.goToPrevTab();
    } else {
      history.push("/ADI/dashboard");
    }
  };

  return (
    <div className="">
      <div
        className={props.parentWrapper === "questionnaire" ? "hidden" : "mb-5"}
      >
        <PageHeader
          title="Job Preferences"
          backToURL={
            props.parentWrapper !== "questionnaire"
              ? "/ADI/settings/overview"
              : ""
          }
        />
      </div>

      <div
        className={`${
          props.parentWrapper === "questionnaire" ? "" : "px-5 py-4"
        } bg-white`}
      >
        {
          <>
            {/* {parentWrapper == 'questionnaire' && props.selfRegisterDate ? (
           <AlertSkip />
         ) : (
           <></>
         )}
         {parentWrapper === 'questionnaire' && !props.selfRegisterDate ? (
           <AlertVerifyData />
         ) : (
           <></>
         )} */}
            <form onSubmit={formik.handleSubmit} className="mt-3">
              {formik.status && (
                <Alert
                  colour="red"
                  icon="pmf-icon-info"
                  title={formik.status}
                />
              )}

              <div className="mb-3">
                <Toggler
                  loading={loading}
                  {...formik.getFieldProps("is_same_gender_job")}
                  label={`Show ${jobPreference.gender_name} learners only`}
                  checked={!!jobPreference.is_same_gender_job}
                  onChange={onFlagJobGenderChanged}
                  disabled={
                    formik.isSubmitting ||
                    !jobPreference.can_change_gender_preference
                  }
                />

                {!jobPreference.can_change_gender_preference && (
                  <p className="text-sm text-red-600 mt-2" id="error">
                    {loading ? (
                      <Skeleton width={100} className="me-2" />
                    ) : (
                      <>
                        This feature is available if you have gender specified
                        in the about you section
                      </>
                    )}
                  </p>
                )}
              </div>

              {props.parentWrapper === "questionnaire" ? (
                <div>
                  {loading ? (
                    <div className="flex items-center space-x-2 mb-3">
                      <Skeleton width={300} height={60} className="me-2" />
                      <Skeleton
                        width={30}
                        height={30}
                        variant="circular"
                        className="me-2"
                      />
                    </div>
                  ) : (
                    <div className="-mx-4" id="availability-collapse">
                      <DisclosureItem
                        disclosureCard
                        disclosureCardSize="md"
                        title="Availability"
                        expanded={
                          submitted || jobPreference.availability.length > 0
                        }
                      >
                        <div className="px-4">
                          <Availability
                            loading={loading}
                            formik={formik}
                            submitted={submitted}
                            weekDays={weekDays}
                            overLap={overLap}
                            availabilityList={formik.values.availability}
                          />
                        </div>
                      </DisclosureItem>
                    </div>
                  )}
                </div>
              ) : (
                <Availability
                  loading={loading}
                  formik={formik}
                  submitted={submitted}
                  weekDays={weekDays}
                  overLap={overLap}
                  availabilityList={formik.values.availability}
                />
              )}

              {loading ? (
                <div>
                  <div className="flex items-center space-x-2 mb-3">
                    <Skeleton width={300} height={60} className="me-2" />
                    <Skeleton
                      width={30}
                      height={30}
                      variant="circular"
                      className="me-2"
                    />
                  </div>
                  <div className="flex items-center space-x-2">
                    <Skeleton width={300} height={60} className="me-2" />
                    <Skeleton
                      width={30}
                      height={30}
                      variant="circular"
                      className="me-2"
                    />
                  </div>
                </div>
              ) : (
                <>
                  {props.parentWrapper === "questionnaire" ? (
                    <div className="-mx-4" id="test-centers-collapse">
                      <DisclosureItem
                        disclosureCard
                        disclosureCardSize="md"
                        title="Test centres"
                        expanded={
                          submitted ||
                          (jobPreference?.adi_test_centers[0] &&
                            jobPreference?.adi_test_centers[0]
                              ?.test_center_id !== "")
                        }
                      >
                        <div className="px-4">
                          <TestCentres
                            values={formik.values.test_centres}
                            label="Please select all the test centres you cover"
                            onChange={(e, key) => onTestCentresChanged(e, key)}
                            options={testCentersBinded}
                            isDisabled={formik.isSubmitting}
                            isSubmitted={submitted}
                            deleteTestCentre={deleteTestCentre}
                            addTestCentre={addTestCentre}
                            errorMsg={formik.errors.test_centres}
                          />
                        </div>
                      </DisclosureItem>
                    </div>
                  ) : (
                    <TestCentres
                      values={formik.values.test_centres}
                      label="Please select all the test centres you cover"
                      onChange={(e, key) => onTestCentresChanged(e, key)}
                      options={testCentersBinded}
                      isDisabled={formik.isSubmitting}
                      isSubmitted={submitted}
                      deleteTestCentre={deleteTestCentre}
                      addTestCentre={addTestCentre}
                      errorMsg={formik.errors.test_centres}
                    />
                  )}
                </>
              )}

              <div
                className={
                  props.parentWrapper === "questionnaire"
                    ? "md:mt-0 mt-[65px]"
                    : ""
                }
              ></div>
              <div
                className={
                  props.parentWrapper === "questionnaire"
                    ? "fixed md:sticky w-full bottom-0 left-0 px-5 md:px-0 py-4 bg-white z-[11]"
                    : ""
                }
              >
                <div
                  className={`${
                    props.parentWrapper === "questionnaire"
                      ? "flex-row-reverse"
                      : "flex-row mt-6 mb-3"
                  } flex gap-x-2 justify-between`}
                >
                  {loading ? (
                    <>
                      <Skeleton width={180} height={50} className="me-2" />
                      <Skeleton width={180} height={50} className="me-2" />
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={setSubmit}
                        type="submit"
                        disabled={formik.isSubmitting}
                        colour="yellow"
                        size="large"
                        halfWidth={true}
                      >
                        Save
                      </Button>
                      <Button
                        onClick={cancel}
                        disabled={formik.isSubmitting}
                        colour="outline"
                        size="large"
                        halfWidth={true}
                      >
                        {props.parentWrapper === "questionnaire"
                          ? "Cancel"
                          : "Discard"}
                      </Button>
                    </>
                  )}
                </div>
              </div>
              {/* 
               {parentWrapper == 'questionnaire' && props.allowSkip ? (
                 <div className='col-12 d-flex order-3 justify-content-center'>
                   <button
                     type='button'
                     className='link--basic btn btn-link'
                     disabled={formik.isSubmitting}
                     onClick={() => props.goToNextTab()}
                   >
                     Skip this step
                   </button>
                 </div>
               ) : (
                 <></>
               )} */}
            </form>
          </>
        }
      </div>
    </div>
  );
};

export { JobPreferences };
