import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchPrismicData } from "../../../../../prismic";
import { PrismicImage } from "@prismicio/react";
import Slider from "react-slick";

const PrismicHeaderLinks: FC<any> = () => {
  const [links, setLinks] = useState<any>();
  const history = useHistory();
  var settings = {
    className: "slider variable-width",
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3.5,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3.25,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1.75,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const contentType = "header_quick_link";
    const results = await fetchPrismicData(contentType);
    let enabledLinks = results.filter(function (a: any) {
      return a.data?.enabled === true;
    });

    let modifiedUrls = enabledLinks.map((a: any) => {
      if (a.data.external_link === false) {
        const startIndex = a.data.link.url.indexOf("/ADI/");
        a.data.link.url =
          startIndex !== -1
            ? a.data.link.url.substring(startIndex)
            : a.data.link.url;
      }
      return a;
    });
    setLinks(modifiedUrls);
  };

  return (
    <>
      <div className={`${links?.length > 0 ? "px-3 pb-5 pt-3" : "mt-3"}`}>
        <Slider {...settings}>
          {links?.map((data: any, index: number) => {
            return (
              <div key={index}>
                <div>
                  <button
                    className="flex mr-3 text-white rounded-full border-white border-2 p-2 h-11  justify-between  items-center"
                    onClick={() => {
                      if (data.data.external_link === true) {
                        window.open(data?.data?.link?.url, "_blank");
                      } else {
                        history.push(data?.data?.link?.url);
                      }
                    }}
                  >
                    <div className="flex justify-between items-center">
                      <div className="rounded-full bg-[#F4F5F7] flex items-center justify-center h-[30px] w-[30px] mr-2">
                        <PrismicImage
                          className="w-5 h-5  self-center object-center object-cover"
                          field={data.data.link_icon}
                        />
                      </div>
                      <div>{data.data.link_title[0].text}</div>
                    </div>
                  </button>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

export { PrismicHeaderLinks };
