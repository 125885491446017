import { PersonalDataModel } from "../../core/models/personal-data-model";
import LinkItem from "../components/shared/elements/LinkItem";
import { toAbsoluteUrl } from "../../content/helpers";
import { useSelector } from "react-redux";
import { RootState } from "../../../setup";

type Props = {
  personalData: PersonalDataModel;
  date: any;
};

const UserMenu: React.FC<Props> = ({ personalData, date }) => {
  const isMobile = useSelector<RootState>(({ auth }) => auth.isMobile);
  return (
    <>
      <div className="flex w-full items-center justify-between space-x-6 pt-3">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h2
              className={` ${
                !isMobile ? "leading-none" : ""
              } truncate  text-2xl font-bold text-white`}
            >
              <>Hi {personalData?.name}</>
            </h2>
          </div>
        </div>
        <LinkItem url="/ADI/settings/overview">
          <img
            src={toAbsoluteUrl("/assets/media/svg/settings.svg")}
            className="h-[26px] w-[26px] flex-shrink-0"
            alt="doc icon"
          />
        </LinkItem>
      </div>
    </>
  );
};

export { UserMenu };
